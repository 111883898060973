import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./navbar.scss";

const Navbar = ({
  onPreviousClick,
  onNextClick,
  className,
  month,
  localeUtils,
  locale,
}) => {
  const currentDate = localeUtils.formatMonthTitle(month, locale);
  return (

    <div className={ cx(className, "navbar") }>
      <button onClick={ () => onPreviousClick() }>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" focusable="false" viewBox="0 0 16 16" aria-hidden="true">
          <path fill="currentColor"
            d="M10.39 12.688a.5.5 0 01-.718.69l-.062-.066-4-5a.5.5 0 01-.054-.542l.054-.082 4-5a.5.5 0 01.83.55l-.05.074L6.641 8l3.75 4.688z"/>
        </svg>
      </button>
      <div className="DayPicker-Caption" role="heading" aria-live="polite">
        <div>{ currentDate }</div>
      </div>
      <button onClick={ () => onNextClick() }>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" focusable="false" viewBox="0 0 16 16" aria-hidden="true">
          <path fill="currentColor"
            d="M5.61 3.312a.5.5 0 01.718-.69l.062.066 4 5a.5.5 0 01.054.542l-.054.082-4 5a.5.5 0 01-.83-.55l.05-.074L9.359 8l-3.75-4.688z"/>
        </svg>
      </button>
    </div>
  );
};

Navbar.propTypes = {
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
  className: PropTypes.string,
  month: PropTypes.instanceOf(Date),
  localeUtils: PropTypes.object,
  locale: PropTypes.string,
};

export default Navbar;