import { Cell, Row } from "@zendeskgarden/react-tables";
import cx from "classnames";
import Item from "components/table/item";
import PickupInstructions from "containers/pickup-instructions";
import ShippingOption from "containers/shipping-option";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { gettext as _ } from "src/utils/translation";

import "styles/regret-purchase-item.scss";
import { itemType } from "types";

const RegretPurchaseItem = ({ rawItem, values, updateItem, isSelected, returnItem, error }) => {
  const [selectedShippingOption, setSelectedShippingOption] = useState();
  const { warehouse } = rawItem;

  const handleReturnOptionChange = (e) => {
    setSelectedShippingOption(e.target.value);
    updateItem(rawItem, "shipping_type", e.target.value);
  };

  const cellWidth = selectedShippingOption === "pickup_home" ? 3 : 6;

  useEffect(() => {
    let cells = document.querySelectorAll(`td[width="${ cellWidth }"]`);
    cells.forEach((cell) => {
      cell.setAttribute("colspan", cellWidth.toString());
    });
  });

  useEffect(() => {
    const isError = (returnItem && !selectedShippingOption) || (selectedShippingOption === "pickup_home" && !returnItem.pickup_datetime);
    if(isError) {
      updateItem(rawItem, "error", true);
    } else {
      updateItem(rawItem, "error", false);
    }
  }, [returnItem, selectedShippingOption]);

  const isError = error && returnItem && !selectedShippingOption;
  const pickupInstructionsClassName = selectedShippingOption === "pickup_home" ? "pickup-instructions-long" : "pickup-instructions-short";
  const errorRef = isError ? returnItem.errorRef : undefined;
  const shippingOptions = warehouse.toString() === "86" ? [
    { label: _("printLabelRadioButton"), value: "return_label" },
    { label: _("packageBoxRadioButton"), value: "package_box" },
    { label: _("pickupRadioButton"), value: "pickup_home" },
    { label: _("selfReturnRadioButton"), value: "self_return" }] : [
    { label: _("pickupRadioButton"), value: "pickup_home" },
    { label: _("selfReturnRadioButton"), value: "self_return" }];

  return <React.Fragment>
    <Item values={ values } isSelected={ isSelected }/>
    <Row key={ `item row ${ values } selected` } className={ cx("row", "row--selected", "regret-purchase-item") }>
      <Cell key={ `item row cell ${ values } radio` } hasOverflow width={ cellWidth }>
        <ShippingOption onChange={ handleReturnOptionChange } current={ selectedShippingOption } error={ isError } shippingOptions={ shippingOptions } errorRef={ errorRef }/>
      </Cell>
      { selectedShippingOption === "pickup_home" && (
        <Cell key={ `item row cell ${ values } date` } hasOverflow width={ cellWidth }>
          <PickupInstructions item={ rawItem } updateItem={ updateItem } returnItem={ returnItem } error={ error } className={ pickupInstructionsClassName }/>
        </Cell>
      ) }
    </Row>
  </React.Fragment>;
};

RegretPurchaseItem.propTypes = {
  values: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
  ])).isRequired,
  rawItem: itemType,
  isSelected: PropTypes.bool,
  updateItem: PropTypes.func,
  returnItem: itemType,
  error: PropTypes.bool,
};

export default RegretPurchaseItem;