import PropTypes, { shape, string, number, bool } from "prop-types";

const itemType = shape({
  amount: number | string,
  canbeautomaticallyhandled: bool,
  canbeexchanged: bool,
  canbereturned: bool,
  coolingoffperiodexpired: bool,
  iid: string,
  name: string,
  shippingdate: string,
  totalprice: number | string,
  unitprice: number | string,
  warehouse: number | string,
  warrantyperiodexpired: bool,
  line_id: string,
  reason_category: string,
  shipping_label: string,
  shipping_type: string,
});

const userType = shape({
  number: PropTypes.string, // order number
  email: PropTypes.string,
  membershipNumber: PropTypes.string,
  taddr_name: PropTypes.string,
  taddr_line1: PropTypes.string,
  taddr_city: PropTypes.string,
  taddr_country: PropTypes.string,
  taddr_zipcode: PropTypes.number | PropTypes.string,
  phone_number: PropTypes.string,
});

export {
  itemType,
  userType,
};