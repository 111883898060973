const RETURN_CONSTANTS = {
  RETURN_REQUEST: "RETURN_REQUEST",
  RETURN_SUCCESS: "RETURN_SUCCESS",
  RETURN_FAILURE: "RETURN_FAILURE",
};

const initialState = {};

export default function returnData(state = initialState, action) {
  switch(action.type) {
    case RETURN_CONSTANTS.RETURN_REQUEST:
      return {
        items: action.returnData && action.returnData.returns,
        loading: true,
      };
    case RETURN_CONSTANTS.RETURN_SUCCESS:
      return {
        items: action.returnData && action.returnData.returns,
        loading: false,
      };
    case RETURN_CONSTANTS.RETURN_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}