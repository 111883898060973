import { Dots, Inline, Spinner } from "@zendeskgarden/react-loaders";
import PropTypes from "prop-types";
import React from "react";
import "./loader.scss";

/**
 *
 * @param size {number} Size of the loader
 * @param color {string} Color of the loader
 * @param type {string} Loader type (dots, inline, spinner)
 */
const Loader = ({ size, color, type = "dots" }) => {
  const getLoader = () => {
    switch(type) {
      case "dots":
        return <Dots size={ size } color={ color }/>;
      case "inline":
        return <Inline size={ size } color={ color }/>;
      case "spinner":
        return <Spinner size={ size } color={ color }/>;
    }
  };
  const loader = getLoader();
  return <div className="loader">
    { loader }
  </div>;
};

Loader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  type: PropTypes.oneOf([
    "dots",
    "inline",
    "spinner",
  ]),
};

export default Loader;