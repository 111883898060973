import "@zendeskgarden/css-bedrock";
import "@zendeskgarden/css-utilities";
import { DEFAULT_THEME, ThemeProvider } from "@zendeskgarden/react-theming";
import "core-js";

import * as React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import "regenerator-runtime/runtime";
import Translation, { getLang } from "src/utils/translation";
import Entry from "./components";

import { history, store } from "./stores";

const App = () => {

  const theme = {
    ...DEFAULT_THEME,
    colors: {
      ...DEFAULT_THEME.colors,
      primaryHue: "#545454",
    },
  };

  Translation.loadTranslations(getLang());

  return (
    <Provider store={ store }>
      <Router history={ history }>
        <ThemeProvider theme={ theme }>
          <Entry/>
        </ThemeProvider>
      </Router>
    </Provider>);
};

render(<App/>, window.document.getElementById("main"));