import { Message } from "@zendeskgarden/react-forms";
import Button from "components/button";
import Title from "components/title";
import ValueLabel from "components/value-label";
import UserInformationEdit from "containers/user-information-edit";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { validateUserInformation } from "src/helpers";
import { gettext as _ } from "src/utils/translation";
import "styles/user-information.scss";
import { userType } from "types";

const UserInformation = ({ error, userInformation, setUserInformation, setUserError, errorRef }) => {
  const { email, membershipnumber, taddr_city, taddr_line1, taddr_name, taddr_postal_code, number, phone_number, order_number } = userInformation;
  const [showEdit, setShowEdit] = useState(false);

  const toggleEdit = () => {
    const isUserError = validateUserInformation(userInformation);
    if(isUserError) {
      setUserError(true);
    } else {
      setUserError(false);
    }
    setShowEdit(!showEdit);
  };

  return (
    <div className="user-information" ref={ error ? errorRef : undefined }>
      <Title size={ "medium" } color={ "dark" }>{ _("userInformationTitleText") }</Title>
      { showEdit ?
        <UserInformationEdit error={ error } setUserInformation={ setUserInformation } userInformation={ userInformation } toggleEdit={ toggleEdit }/> :
        <React.Fragment>
          <ValueLabel value={ number || order_number } label={ _("orderNumberInputLabel") }/>
          <br/>
          { membershipnumber && (<ValueLabel value={ membershipnumber } label={ _("membershipNumberInputLabel") }/>) }
          <ValueLabel value={ taddr_name } label={ _("fullNameInputLabel") }/>
          <ValueLabel value={ taddr_line1 } label={ _("addressInputLabel") }/>
          <ValueLabel value={ `${ taddr_postal_code } ${ taddr_city }` } label={ _("postalCodeCityLabel") }/>
          <ValueLabel value={ phone_number } label={ _("phoneNumberInputLabel") }/>
          <ValueLabel value={ email } label={ _("emailInputLabel") }/>
          <br/>
          { setUserInformation && <Button onClick={ toggleEdit } size={ "medium" } text={ _("editUserInformationButton") } isStretched={ false } isPrimary/> }
          { error && (
            <Message validation="error" style={ { marginTop: "8px" } }>
              { _("userInformationErrorMessage") }
            </Message>
          ) }
        </React.Fragment>
      }
    </div>
  );
};

UserInformation.propTypes = {
  error: PropTypes.bool,
  userInformation: userType,
  setUserInformation: PropTypes.func,
  setUserError: PropTypes.func,
  errorRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default UserInformation;
