/**
 * Represents the sizes that the text can be.
 *
 * @type {{SMALL: string, LARGE: string}}
 */
export const SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

/**
 * Represents the colors that the color can be.
 *
 * @type {{DARK: string, LIGHT: string}}
 */
export const COLORS = {
  DARK: "dark",
  LIGHT: "light",
};

/**
 * Represents the color that the background can be.
 *
 * @type {{DARK: string, LIGHT: string, TRANSPARENT:string}}
 */
export const BACKGROUND = {
  DARK: "dark",
  LIGHT: "light",
  TRANSPARENT: "transparent",
};