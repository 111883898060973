import React from "react";
import "styles/footer.scss";

const Footer = () => {
  return <footer className="footer">
    <div className="footer-inner">
      <p>Coop Danmark A/S</p>
      <p>Roskildevej 65, 2620 Albertslund</p>
      <p>CVR-nr. 26259495</p>
      <p>kundeservice@kontakt.coop.dk</p>
    </div>
  </footer>;
};

export default Footer;
