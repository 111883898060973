const USER_CONSTANTS = {
  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  AUTH_REQUEST: "USER_AUTH_REQUEST",
  AUTH_SUCCESS: "USER_AUTH_SUCCESS",
  AUTH_FAILURE: "USER_AUTH_FAILURE",

  LOGOUT: "USER_LOGOUT",
};

const initialState = {};

export default function authentication(state = initialState, action) {
  switch(action.type) {
    case USER_CONSTANTS.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case USER_CONSTANTS.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case USER_CONSTANTS.LOGIN_FAILURE:
      return {
        error: action.error,
      };
    case USER_CONSTANTS.AUTH_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case USER_CONSTANTS.AUTH_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case USER_CONSTANTS.AUTH_FAILURE:
      return {
        loggedIn: false,
      };
    case USER_CONSTANTS.LOGOUT:
      return {
        loggedIn: false,
      };
    default:
      return state;
  }
}