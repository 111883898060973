import Loader from "components/loader";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ component: Component, authentication, ...rest }) => {
  const isAuthenticated = (authentication && authentication.loggedIn);
  const isLoggingIn = authentication && authentication.loggingIn;

  if(isLoggingIn) {
    return <Loader/>;
  } else {
    return (
      <Route { ...rest } render={ props => {
        return (
          isAuthenticated
            ? <Component { ...props } />
            : <Redirect to={ { pathname: "/", state: { from: props.location } } }/>
        );
      } }/>
    );
  }
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  authentication: PropTypes.object,
  location: PropTypes.object,
  routeRender: PropTypes.func,
};

export default connect(
  state => ({
    authentication: state.authentication,
  }),
)(PrivateRoute);