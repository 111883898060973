import { put } from "./index";

const submitReturn = (orderNumber, customer, items) => {
  const { email, membershipnumber, taddr_city, taddr_country, taddr_line1, taddr_name, taddr_postal_code, phone_number } = customer;
  return put("/returns/", {
    order_number: orderNumber,
    email,
    membershipnumber,
    taddr_city,
    taddr_country,
    taddr_line1,
    taddr_name,
    taddr_postal_code,
    phone_number,
    lines: items,
  }, undefined);

};

export {
  submitReturn,
};
