import { submitReturn as apiSubmitReturn } from "../api/submitReturn";
import { history } from "../stores";

const RETURN_CONSTANTS = {
  RETURN_REQUEST: "RETURN_REQUEST",
  RETURN_SUCCESS: "RETURN_SUCCESS",
  RETURN_FAILURE: "RETURN_FAILURE",
};

const returnActions = {
  submitReturn,
};

function submitReturn(orderNumber, customer, items) {
  return dispatch => {
    dispatch(request({ orderNumber, customer, items }));

    apiSubmitReturn(orderNumber, customer, items)
      .then(
        returnData => {
          dispatch(success(returnData));
          history.push("/summary");
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request(returnRequest) {
    return { type: RETURN_CONSTANTS.RETURN_REQUEST, returnRequest };
  }

  function success(returnData) {
    return { type: RETURN_CONSTANTS.RETURN_SUCCESS, returnData };
  }

  function failure(error) {
    return { type: RETURN_CONSTANTS.RETURN_FAILURE, error };
  }
}

export {
  RETURN_CONSTANTS,
  returnActions,
};