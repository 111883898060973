import { post, deleteReq } from "./index";

const login = (email, orderNumber) => {
  return post(
    "/login/",
    {
      "number": orderNumber,
      "email": email,
    },
  );
};

const logout = () => {
  return deleteReq("/login/", undefined);
};

export {
  login,
  logout,
};
