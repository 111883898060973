import Button from "components/button";
import InputField from "components/input-field";
import Loader from "components/loader";
import Title from "components/title";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { userActions } from "src/actions/userActions";
import { validateEmail } from "src/helpers";
import { gettext as _ } from "src/utils/translation";

import "styles/login.scss";

export const COOP_HELPDESK_GENERAL_FORM = "https://coopshopping.zendesk.com/hc/da/requests/new?ticket_form_id=4421713024914";

const Login = ({ login, authentication }) => {
  const [email, setEmail] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    if(!validateEmail(email) || !orderNumber) {
      setError(true);
    } else {
      login(email, orderNumber);
    }
  };

  const emailError = error && !validateEmail(email);
  const orderNumberError = error && (!orderNumber || orderNumber === "");

  const hint = <span>{ _("orderNumberTooltip") }</span>;

  const isAuthenticated = (authentication && authentication.loggedIn);
  const badCredentials = authentication && authentication.error;

  const onChangeEmail = (e) => {
    const email = e.target.value;
    const emailWithoutSpaces = email.replace(/\s+/g, "");
    const emailLowerCase = emailWithoutSpaces.toLowerCase();
    setEmail(emailLowerCase);
  };

  useEffect(() => {
    const listener = event => {
      if(event.code === "Enter" || event.code === "NumpadEnter") {
        handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email, orderNumber]);

  if(isAuthenticated) {
    return <Redirect push to={ "/return" }/>;
  }

  const loading = authentication.loggingIn;

  return (
    <div className="login-form">
      <div className="login-form-elements">
        <Title size={ "medium" } color={ "dark" }>{ _("signInTitleText") }</Title>
        <InputField
          label={ _("orderNumberInputLabel") }
          placeholder={ _("orderNumberInputPlaceholder") }
          onChange={ (e) => setOrderNumber(e.target.value) }
          hint={ hint }
          value={ orderNumber }
          isError={ orderNumberError }
          errorMessage={ _("orderNumberInputErrorMessage") }/>
        <a href={ COOP_HELPDESK_GENERAL_FORM }>{ _("orderNumberFormLink") }</a>
        <InputField
          label={ _("emailInputLabel") }
          placeholder={ _("emailInputPlaceholder") }
          onChange={ onChangeEmail }
          value={ email }
          isError={ emailError }
          errorMessage={ _("emailInputErrorMessage") }/>
        <a href={ COOP_HELPDESK_GENERAL_FORM }>{ _("emailFormLink") }</a>
        { loading ? <Loader type="inline" size={ 20 }/> :
          <Button text={ _("signInButtonText") } onClick={ handleSubmit } size={ "medium" } isStretched isPrimary/> }
        { badCredentials &&
          <div className="error-message">
            { _("loginErrorMessage") } <br/>
            <a href={ COOP_HELPDESK_GENERAL_FORM }>{ _("loginErrorLink") }</a>
          </div>
        }
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func,
  authentication: PropTypes.object,
};

export default connect(
  state => ({
    authentication: state.authentication,
  }),
  {
    login: userActions.login,
  },
)(Login);
