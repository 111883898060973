import { Field, Input, Label, Message } from "@zendeskgarden/react-forms";
import Tooltip from "components/tooltip";
import PropTypes from "prop-types";
import React from "react";
import questionMark from "src/images/question-mark.svg";
import "./input-field.scss";

/**
 * Input field component.
 *
 * @param {string}  label - The title shown above the input field
 * @param {string}  placeholder - The placeholder text shown in the input field.
 * @param {string}  value - The text typed into the input field.
 * @param {function}  onChange -  An event handler for onChange events. Is activated when the input changes.
 * @param {boolean}  isCompact - Used to apply compact styling.
 * @param {boolean}  disabled - Used to define if the input field should be disabled.
 * @param {boolean}  required - Used to define if the input field is required, renders an asterisk
 * @param {string} hint - Tooltip content
 * @param {boolean} isError - Used to define if there was an error in the input
 * @param {string} name - Attribute is used to specify a name for an <input> element. It is used to reference the form-data after submitting the form or to
 * reference the element in a JavaScript.
 * @param {string} errorMessage - Message that shows up if there wan an error in the input (isError = true)
 */
const InputField = ({ label, placeholder, value, onChange, isCompact, disabled, required, hint, isError, name, errorMessage }) => {
  const validation = isError ? "error" : null;
  return (
    <Field className="input-field">
      { label && (<Label isRegular>{ label }</Label>) }
      { required && <span className="input-field--required">*</span> }
      { hint && <Tooltip size={ "extra-large" } content={ hint }>
        <img alt="question-mark" className="hint" src={ questionMark }/>
      </Tooltip> }
      <Input
        placeholder={ placeholder }
        value={ value }
        onChange={ onChange }
        disabled={ disabled }
        isCompact={ isCompact }
        validation={ validation }
        name={ name }/>
      { isError && <Message validation="error">{ errorMessage }</Message> }
    </Field>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isCompact: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hint: PropTypes.element,
  isError: PropTypes.bool,
  name: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default InputField;