import { Dropdown as GardenDropdown, Field, Menu, Item, Select, Label } from "@zendeskgarden/react-dropdowns";
import PropTypes from "prop-types";
import React from "react";
import { gettext as _ } from "src/utils/translation";
import deleteIcon from "src/images/delete_gray.svg";
import "./dropdown.scss";

/**
 * Dropdown component.
 *
 * @param {string} label - The title shown above the input field
 * @param {DropdownItem} selectedItem - Currently selected item in the dropdown, needs to be in format of DropdownItem
 * @param {DropdownItem []} items - Array of items for dropdown, they need to be in format of DropdownItem
 * @param {function} onSelect -  An event handler for onSelect events. Is activated when the input changes.
 * @param {string} placeholder - The placeholder text shown before any items is selected
 * @param {function} removeSelection - Function to remove the current selection - reset the dropdown
 * @param {boolean} error - Indicates if validation error styling should be displayed
 */
const Dropdown = ({ selectedItem, onSelect, items, label, placeholder, removeSelection, error }) => {
  const selectedItemLabel = selectedItem ? selectedItem.label : placeholder;
  const validation = error && "error";
  return (
    <GardenDropdown
      selectedItem={ selectedItem }
      onSelect={ onSelect }
      downshiftProps={ { itemToString: (item) => item && item.label } }>
      <Field>
        { label && <Label>{ _(label) }</Label> }
        <Select validation={ validation } className={ validation && "validation" }>
          <div className="select">
            { _(selectedItemLabel) }
            { removeSelection && selectedItem && <img alt="delete" src={ deleteIcon } onClick={ removeSelection }/> }
          </div>
        </Select>
      </Field>
      <Menu>
        { items.map(option => (
          <Item key={ option.value } value={ option }>
            { _(option.label) }
          </Item>
        )) }
      </Menu>
    </GardenDropdown>);
};

const DropdownItem = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

Dropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedItem: DropdownItem,
  items: PropTypes.arrayOf(DropdownItem).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  removeSelection: PropTypes.func,
  error: PropTypes.bool,
};

export default Dropdown;