import Item from "components/table/item";
import PropTypes from "prop-types";
import React from "react";
import { renderReturnReasonItem } from "src/helpers";
import { itemType } from "types";

/**
 * Table item component.
 *
 * @param {boolean} isDisabled - Used to define if the item should be disabled.
 * @param {boolean} isSelected - Used to define if the item should be selected.
 * @param {$ElementType []} values - Array of react components that represent table cells (values) in one row
 * @param {itemType} returnItem
 * @param {function} updateItem
 * @param {itemType} rawItem - Original item from the order (without return reason properties)
 * @param {boolean} error - There was an error on submit
 */
const ReturnItem = ({ isDisabled, isSelected, values, returnItem, updateItem, rawItem, error }) => {
  return returnItem ? (
    renderReturnReasonItem(returnItem, values, isSelected, updateItem, rawItem, isDisabled, error)
  ) : (
    <Item values={ values } isSelected={ isSelected } isDisabled={ isDisabled }/>
  );
};

ReturnItem.propTypes = {
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  isTruncated: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
  ])).isRequired,
  returnItem: itemType,
  updateItem: PropTypes.func,
  rawItem: itemType,
  error: PropTypes.bool,
};

export default ReturnItem;