import cx from "classnames";
import Datepicker from "components/date-picker";
import MultilineText from "components/multiline-text";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { dateFormat } from "src/helpers";
import "src/styles/pickup-instructions.scss";
import { gettext as _ } from "src/utils/translation";
import { itemType } from "types";

function addBusinessDays(date, busDays) {
  const weekday = date.getDay(); // get weekday number
  const addDays = weekday >= 3 ? (busDays + 2) : busDays; // if it's wednesday or later set add days to 5 instead of 3 to account for the weekend
  date.setDate(date.getDate() + addDays); // add days to current date
  return date;
}

const PickupInstructions = ({ item, updateItem, returnItem, error, className }) => {
  const [selectedDate, setSelectedDate] = useState();
  const handlePickupInstructionsChange = (e) => {
    updateItem(item, "pickup_instructions", e.target.value);
  };

  const handlePickupDateChange = (date) => {
    let formattedDate = dateFormat(date);

    setSelectedDate(date);
    //TODO: remove once we add self return shipping option
    if(item.warehouse.toString() !== "86") {
      updateItem(item, "shipping_type", "pickup_home");
    }
    updateItem(item, "pickup_datetime", formattedDate);
  };

  useEffect(() => {
    if(returnItem && !selectedDate) {
      updateItem(item, "error", true);
    } else {
      updateItem(item, "error", false);
    }
  }, [returnItem, selectedDate]);

  const errorMessage = error && returnItem && !selectedDate ? _("pickupDateErrorMessage") : null;
  const errorRef = errorMessage ? returnItem.errorRef : undefined;
  const today = new Date();
  const warehouse = item.warehouse;
  //Warehouse 86: 2 days
  //Warehouse 31/78: 5 days (Due to the manual process they need more days)
  const minDate = warehouse === "86" ? addBusinessDays(today, 2) : addBusinessDays(today, 5);

  return (
    <div className={ cx("pickup-instructions", className) } ref={ errorRef }>
      <Datepicker
        onSelect={ handlePickupDateChange }
        label={ _("pickupDatepickerLabel") }
        date={ selectedDate }
        minValue={ minDate }
        errorMessage={ errorMessage }/>
      <MultilineText label={ _("pickupInstructionsMultilineLabel") } onChange={ handlePickupInstructionsChange }/>
    </div>
  );
};

PickupInstructions.propTypes = {
  item: itemType,
  updateItem: PropTypes.func,
  returnItem: itemType,
  error: PropTypes.bool,
  className: PropTypes.string,
};

export default PickupInstructions;