import Table from "components/table";
import AmountInput from "containers/amount-input";
import ReturnItem from "containers/return-reason-table-items/return-item";
import ReturnReasonsDropdown from "containers/return-reasons-dropdown";
import PropTypes from "prop-types";
import React from "react";
import { gettext as _ } from "src/utils/translation";

import "styles/order-information.scss";
import { itemType } from "types";

const OrderInformation = ({ orderNumber, itemsToReturn, setItemsToReturn, rawItems, error }) => {

  const tableHeaderValues = [
    _("tableHeaderValueItem"),
    _("tableHeaderValueAmount"),
    _("tableHeaderValuePrice"),
    _("tableHeaderValueDate"),
    _("tableHeaderValueReturnAmount"),
    _("tableHeaderValueReturnReason"),
  ];

  const isItemSelected = (item) => itemsToReturn.length > 0 ? itemsToReturn.some(returnItem => returnItem.line_id === item.line_id) : false;
  const getReturnItem = (item) => {
    if(itemsToReturn.length) {
      return itemsToReturn.filter(returnItem => returnItem.line_id === item.line_id)[0];
    } else {
      return null;
    }
  };

  const handleSelectReturnReason = (returnReason, item) => {
    updateItem(item, "reason_category", returnReason.value);
  };

  const handleRemoveReturnReason = (item) => {
    removeItem(item);
  };

  const removeItem = (item) => {
    setItemsToReturn(itemsToReturn.filter(x => x.line_id !== item.line_id));
  };

  const updateItem = (item, property, value) => {
    let index = itemsToReturn.findIndex(x => x.line_id === item.line_id);

    let existingItem = itemsToReturn[index];
    if(index !== -1) {
      existingItem[property] = value;
      setItemsToReturn([
        ...itemsToReturn.slice(0, index),
        existingItem,
        ...itemsToReturn.slice(index + 1),
      ],
      );
    } else {
      setItemsToReturn([...itemsToReturn, {
        ...item,
        [property]: value,
      }]);
    }
  };

  const itemIsDisabled = (item) => {
    const { canbereturned, coolingoffperiodexpired, warrantyperiodexpired } = item;
    return !canbereturned || (coolingoffperiodexpired && warrantyperiodexpired);
  };

  return (
    <div className="order-information">
      <Table title={ _(`Order ${ orderNumber }`) } headerValues={ tableHeaderValues }>
        { rawItems && rawItems.map((item, index) => {
          const returnItem = getReturnItem(item);
          const date = item.shippingdate ? new Date(item.shippingdate).toLocaleDateString() : null;
          if(returnItem && !returnItem.returnAmount) {
            updateItem(item, "returnAmount", 1);
          }
          const values = [
            item.name, parseInt(item.amount), Number(item.totalprice), date,
            <AmountInput
              key={ `AmountInput ${ index }` }
              disabled={ (parseInt(item.amount) <= 1) || itemIsDisabled(item) }
              updateItem={ updateItem }
              item={ item }
              max={ parseInt(item.amount) }
              min={ 1 }
              startingValue={ 1 }/>,
            <ReturnReasonsDropdown
              key={ `return reason ${ index }` }
              returnReason={ returnItem && returnItem.reason_category }
              handleSelectReturnReason={ handleSelectReturnReason }
              item={ item }
              isDisabled={ itemIsDisabled(item) }
              handleRemoveReturnReason={ handleRemoveReturnReason }
              returnItem={ returnItem }
              updateItem={ updateItem }/>,
          ];

          return <ReturnItem
            key={ `table item ${ index }` }
            values={ values }
            isSelected={ isItemSelected(item) }
            isDisabled={ itemIsDisabled(item) }
            returnItem={ returnItem }
            rawItem={ item }
            updateItem={ updateItem }
            error={ error }/>;
        }) }
      </Table>
    </div>
  );
};

OrderInformation.propTypes = {
  orderNumber: PropTypes.string,
  itemsToReturn: PropTypes.arrayOf(itemType),
  setItemsToReturn: PropTypes.func,
  rawItems: PropTypes.arrayOf(itemType),
  error: PropTypes.bool,
};

export default OrderInformation;
