import React from "react";
import { string } from "prop-types";
import "./value-label.scss";

/**
 *
 * @param value {string}
 * @param label {string}
 * @returns {JSX.Element}
 * @constructor
 */
const ValueLabel = ({ value, label }) => (
  <div className="value-label">
    <label>{ label }: </label>
    <span>{ value }</span>
  </div>
);

ValueLabel.propTypes = {
  value: string,
  label: string,
};

export default ValueLabel;