import React from "react";
import "styles/header.scss";

const Header = () => {
  return <div className="header">
    <div className="link-back">
      <div className="link-back__container">
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.06204 0.0504169L0.11245 5L5.06203 9.94958L5.88745 9.12475L1.76212 5L5.88745 0.875252L5.06204 0.0504169Z"/>
        </svg>
        <a href="https://shopping.coop.dk/">
          Tilbage til Coop.dk
        </a>
      </div>
    </div>

    <div className="branding">
      <div className="branding__container">
        <div className="logo">
          <a title="Startside" href="https://kundeservice.shopping.coop.dk/hc/da">
            <img className="logo-img" src="//theme.zdassets.com/theme_assets/10596894/a999e1444e0b00df7aeca94c8fa71f4cd1b53543.svg" alt="Kundeservice Help Center startside"/>
          </a>
        </div>
        <div className="buttons">
          <a href="https://kundeservice.shopping.coop.dk/hc/da/requests/new?ticket_form_id=360001378760" className="button">
            <img alt="kontakt" src="//theme.zdassets.com/theme_assets/10596894/e36445b7b09e686852c73d62a7f8e5c18fbaed1b.svg" className="button__icon contact"/>
            <div>Kontakt</div>
          </a>
          <a href="https://shopping.coop.dk/Varekurv" className="button">
            <img alt="kurv" src="//theme.zdassets.com/theme_assets/10596894/a9681b8f907f0982dc4909ec0251089ccf202119.svg" className="button__icon basket"/>
            <div>Kurv</div>
          </a>
        </div>
      </div>
    </div>
  </div>;
};

export default Header;
