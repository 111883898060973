import Item from "components/table/item";
import ClaimDefect from "containers/return-reason-table-items/claim-defekt-item";
import RegretPurchaseItem from "containers/return-reason-table-items/regret-purchase-item";
import WrongItem from "containers/return-reason-table-items/wrong-item";
import React from "react";

const renderReturnReasonItem = (returnItem, values, isSelected, updateItem, rawItem, isDisabled, error) => {
  switch(returnItem.reason_category) {
    case "regret":
      return <RegretPurchaseItem
        values={ values }
        rawItem={ rawItem }
        isSelected={ isSelected }
        updateItem={ updateItem }
        returnItem={ returnItem }
        error={ error }/>;
    case "wrong_item":
      return <WrongItem values={ values } rawItem={ rawItem } isSelected={ isSelected } updateItem={ updateItem } returnItem={ returnItem } error={ error }/>;
    case "defect":
      return <ClaimDefect values={ values } rawItem={ rawItem } isSelected={ isSelected } updateItem={ updateItem } returnItem={ returnItem } error={ error }/>;
    default:
      return <Item values={ values } isSelected={ isSelected } isDisabled={ isDisabled }/>;
  }
};

const validatePostCode = (postCode) => {
  const postCodeRegex = new RegExp("^(?:[1-24-9]\\d{3}|3[0-8]\\d{2})$");
  return postCodeRegex.test(postCode);
};

const validatePhoneNumber = (phoneNumber) => {
  const phoneNumberRegex = new RegExp("^[+]?(?:\\(\\d+(?:\\.\\d+)?\\)|\\d+(?:\\.\\d+)?)(?:[ -]?(?:\\(\\d+(?:\\.\\d+)?\\)|\\d+(?:\\.\\d+)?))*(?:[ ]?(?:x|ext)\\.?[ ]?\\d{1,5})?$");
  return phoneNumberRegex.test(phoneNumber);
};

const validateEmail = (email) => {
  const emailRegex = new RegExp("^[-!#-'*+\\/-9=?^-~]+(?:\\.[-!#-'*+\\/-9=?^-~]+)*@[-!#-'*+\\/-9=?^-~]+(?:\\.[-!#-'*+\\/-9=?^-~]+)+$");
  return emailRegex.test(email);
};

const validateUserInformation = (userInformation) => {
  const { email, number, taddr_city, taddr_line1, taddr_name, taddr_postal_code, phone_number } = userInformation;
  return (!validateEmail(email) || !taddr_city || !taddr_line1 || !taddr_name || !validatePhoneNumber(phone_number) || !number || !validatePostCode(taddr_postal_code));
};

const validateItems = (items) => {
  return items.some(returnItem => {
    const reason = returnItem.reason_category;
    return returnItem.error === true || !reason;
  });
};

const removeItemsWithoutReturnReason = (items) => {
  return items.filter(item => item.reason_category);
};

/**
 * Converts datetime to yy-mm-dd
 * @type {string|*}
 */
const dateFormat = (date) => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - (offset * 60 * 1000));
  return date.toISOString().split("T")[0];
};

export {
  validateUserInformation,
  renderReturnReasonItem,
  validatePostCode,
  validatePhoneNumber,
  validateEmail,
  validateItems,
  dateFormat,
  removeItemsWithoutReturnReason,
};