import Loader from "components/loader";
import FileInput from "containers/upload-images/file-input";
import Images from "containers/upload-images/images";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { gettext as _ } from "src/utils/translation";

import "src/styles/image-upload.scss";

const ImageUpload = ({ images, setImages, id }) => {
  const [uploading, setUploading] = useState(false);

  const removeImage = (id) => {
    setImages(images.filter(image => image.file_token !== id));
  };

  const onChange = (e) => {
    const image = e.target.files[0];
    setUploading(true);

    const formData = new FormData();
    formData.append("file", image);

    fetch("api/uploads", {
      method: "POST",
      body: formData,
    })
      .then(res => res.json())
      .then(response => {
        setUploading(false);
        setImages([...images, { name: image.name, file_token: response.file_token }]);
      });
  };

  const imageLimit = images.length >= 6;

  return (
    <div className="image-upload">
      { uploading ? <Loader/> : <React.Fragment>
        { imageLimit ? <span> { _("imageLimitMessage") } </span> : <FileInput id={ id } onChange={ onChange }/> }
        <Images images={ images } removeImage={ removeImage }/>
      </React.Fragment> }
    </div>
  );
};

ImageUpload.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      file_token: PropTypes.string,
    },
    )),
  setImages: PropTypes.func,
  id: PropTypes.string,
};

export default ImageUpload;