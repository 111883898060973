import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import { SIZES, COLORS, BACKGROUND } from "./constants";
import "./title.scss";

/**
 * Component for a Title.
 *
 * @property {string} props.children - The text displayed.
 * @property {string} props.size - The size of the text, can be small, medium or large.
 * @property {string} props.color - The color of the text, can be dark or light.
 * @property {string} props.background - The color of the background, can be dark, light or transparent
 */
const Title = ({ children, size, background, color}) => {
  const _className = cx("title-text", {
    "title-text--small": size === SIZES.SMALL,
    "title-text--medium": size === SIZES.MEDIUM,
    "title-text--large": size === SIZES.LARGE,
    "title-text--bg-dark": background === BACKGROUND.DARK,
    "title-text--bg-light": background === BACKGROUND.LIGHT,
    "title-text--bg-transparent": background === BACKGROUND.TRANSPARENT,
    "title-text--dark": color === COLORS.DARK,
    "title-text--light": color === COLORS.LIGHT,
  });

  return (
    <div className={ _className }>
      { children }
    </div>
  );
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
  size: PropTypes.oneOf([
    "small",
    "medium",
    "large",
  ]),
  background: PropTypes.oneOf([
    "dark",
    "light",
  ]),
  color: PropTypes.oneOf([
    "dark",
    "light",
  ]),
};

export default Title;