import Dropdown from "components/dropdown";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { gettext as _ } from "src/utils/translation";

import "styles/return-reason-dropdown.scss";
import { itemType } from "types";

const ReturnReasonsDropdown = ({ returnReason, handleSelectReturnReason, item, isDisabled, handleRemoveReturnReason, updateItem }) => {

  useEffect(() => {
    if(returnReason === "regret") {
      updateItem(item, "return_action", "reimbursement");
    }
  }, []);

  const selectDropdownItems = (item) => {
    const { coolingoffperiodexpired, warrantyperiodexpired } = item;
    if(coolingoffperiodexpired) {
      return [
        { label: "dropdownDefect", value: "defect" },
      ];
    }
    if(warrantyperiodexpired) {
      return [
        { label: "dropdownRegret", value: "regret" },
        { label: "dropdownWrongItem", value: "wrong_item" },
      ];
    }
    return [
      { label: "dropdownRegret", value: "regret" },
      { label: "dropdownWrongItem", value: "wrong_item" },
      { label: "dropdownDefect", value: "defect" },
    ];
  };
  const dropdownItems = selectDropdownItems(item);
  const selectedItem = dropdownItems.filter(reason => {
    if(reason.value === returnReason) {
      return reason;
    }
  })[0];

  const [selected, setSelected] = useState(selectedItem);

  const handleOnSelect = (dropdownItem) => handleSelectReturnReason(dropdownItem, item);
  const handleRemoveSelection = (e) => {
    e.stopPropagation();
    handleRemoveReturnReason(item);
    setSelected(null);
  };

  return (
    <div className={ "return-reason-dropdown" }>
      { isDisabled ? (
        <span>{ _("canNotBeReturnedText") }</span>
      ) : (
        <Dropdown
          key={ 1 }
          items={ dropdownItems }
          onSelect={ handleOnSelect }
          selectedItem={ selected }
          placeholder={ _("returnReasonDropdownPlaceholder") }
          removeSelection={ handleRemoveSelection }
        />
      ) }

    </div>
  );
};

ReturnReasonsDropdown.propTypes = {
  returnReason: PropTypes.string,
  item: itemType,
  handleSelectReturnReason: PropTypes.func,
  isDisabled: PropTypes.bool,
  handleRemoveReturnReason: PropTypes.func,
  updateItem: PropTypes.func,
};

export default ReturnReasonsDropdown;