export function get_cookie(name) {
  if(document.cookie && document.cookie !== "") {
    let cookies = document.cookie.split(";");

    for(let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();

      if(cookie.substring(0, name.length + 1) === (name + "=")) {
        return decodeURIComponent(cookie.substring(name.length + 1));
      }
    }
  }

  return null;
}

export function delete_cookie(name, path) {
  if(get_cookie(name)) {
    document.cookie = name + "=" +
      ((path) ? ";path=" + path : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

export function isDefined(prop) {
  return typeof prop !== "undefined";
}

export function hasLength(string) {
  return string.length > 0;
}

export function hasChanged(prevValue, newValue) {
  return prevValue.toString() !== newValue.toString();
}