import { createBrowserHistory } from "history";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers";

export const history = createBrowserHistory();

export const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware,
  ),
);
