import Loader from "components/loader";
import PrivateRoute from "components/private-route";
import Footer from "containers/footer";
import Header from "containers/header";
import Login from "containers/pages/login";
import Return from "containers/pages/return";
import Summary from "containers/pages/summary";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { userActions } from "src/actions/userActions";

const Entry = ({ checkAuthenticated, authentication }) => {

  useEffect(() => {
    checkAuthenticated();
    document.title = "Coop retur- og reklamationsformular";
  }, []);

  if(Object.keys(authentication).length > 0) {
    return (
      <>
        <Header/>
        <Switch>
          <Route exact path="/" component={ Login }/>
          <PrivateRoute exact path="/return" component={ Return }/>
          <PrivateRoute exact path="/summary" component={ Summary }/>
          <Route component={ Login }/>
        </Switch>
        <Footer/>
      </>
    );
  } else {
    return <Loader/>;
  }

};

Entry.propTypes = {
  authentication: PropTypes.object,
  location: PropTypes.object,
  checkAuthenticated: PropTypes.func,
};

export default withRouter(connect(
  state => ({
    authentication: state.authentication,
  }),
  {
    checkAuthenticated: userActions.checkAuthenticated,
  },
)(Entry));
