import { combineReducers } from "redux";
import returnData from "src/reducers/returnReducer";
import authReducer from "./authReducer";
import orderReducer from "./orderReducer";

const appReducer = combineReducers({
  authentication: authReducer,
  orderItems: orderReducer,
  returnData: returnData,
});

const rootReducer = (state, action) => {
  if(action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
