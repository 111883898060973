import { delete_cookie, get_cookie } from "src/utils";
import { checkAuthenticated as apiCheckAuthenticated } from "../api/checkAuthenticated";
import { login as apiLogin, logout as apiLogout } from "../api/login";

const USER_CONSTANTS = {
  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  AUTH_REQUEST: "USER_AUTH_REQUEST",
  AUTH_SUCCESS: "USER_AUTH_SUCCESS",
  AUTH_FAILURE: "USER_AUTH_FAILURE",

  LOGOUT: "USER_LOGOUT",
};

function login(email, orderNumber) {
  return dispatch => {
    dispatch(request({ email, orderNumber }));

    apiLogin(email, orderNumber)
      .then(
        user => {
          dispatch(success(user));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request(user) {
    return { type: USER_CONSTANTS.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: USER_CONSTANTS.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: USER_CONSTANTS.LOGIN_FAILURE, error };
  }
}

const checkAuthenticated = () => {
  return async dispatch => {
    dispatch(request());
    apiCheckAuthenticated()
      .then(
        user => {
          dispatch(success(user));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: USER_CONSTANTS.AUTH_REQUEST };
  }

  function success(user) {
    return { type: USER_CONSTANTS.AUTH_SUCCESS, user };
  }

  function failure(error) {
    return { type: USER_CONSTANTS.AUTH_FAILURE, error };
  }
};

function logout() {
  apiLogout().then(() => {
    const csrf = get_cookie("csrftoken");
    if(csrf && csrf !== "") {
      delete_cookie("csrftoken", "/");
    }
  });

  return { type: USER_CONSTANTS.LOGOUT };
}

const userActions = {
  login,
  logout,
  checkAuthenticated,
};

export {
  USER_CONSTANTS, userActions,
};