import { Alert, Title } from "@zendeskgarden/react-notifications";
import Button from "components/button";
import Loader from "components/loader";
import OrderInformation from "containers/order-information";
import { COOP_HELPDESK_GENERAL_FORM } from "containers/pages/login";
import UserInformation from "containers/user-information";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { returnActions } from "src/actions/returnActions";
import { userActions } from "src/actions/userActions";
import { removeItemsWithoutReturnReason, validateItems, validateUserInformation } from "src/helpers";
import { gettext as _ } from "src/utils/translation";
import "styles/return.scss";
import { userType } from "types";

const Return = ({ submitReturn, authentication, returnData, logout }) => {
  const [userError, setUserError] = useState(false);
  const [userInformation, setUserInformation] = useState(authentication.user.order);
  const [itemsError, setItemsError] = useState(false);
  const [itemsToReturn, setItemsToReturn] = useState([]);
  const history = useHistory();
  const cleanItems = removeItemsWithoutReturnReason(itemsToReturn);
  const errorRef = createRef();
  const loading = returnData.loading || false;

  useEffect(() => {
    return () => {
      if(history.action === "POP") {
        logout();
        history.push("/");
      }
    };
  }, []);

  useEffect(() => {
    const itemsWithErrorRef = itemsToReturn.filter(item => item.errorRef?.current);
    itemsWithErrorRef[0]?.errorRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [itemsToReturn]);

  const handleSubmit = () => {
    const isUserError = validateUserInformation(userInformation);
    const isItemError = cleanItems.length === 0 || validateItems(cleanItems);
    if(isUserError) {
      setUserError(true);
      errorRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
    if(isItemError) {
      setItemsError(true);
      let errorRefUsed = false;
      const sortedItems = itemsToReturn.sort((a, b) => b.line_id - a.line_id);

      const addErrorRef = sortedItems.map(returnItem => {
        if(returnItem.error && !errorRefUsed) {
          errorRefUsed = true;
          return {
            ...returnItem,
            errorRef,
          };
        } else {
          return returnItem;
        }
      });
      setItemsToReturn(addErrorRef);
    }
    if(!(isUserError || isItemError)) {
      setUserError(false);
      setItemsError(false);
      return submitReturn(userInformation.number, userInformation, cleanItems);
    }
  };

  const handleCancel = () => {
    logout();
    history.push("/");
  };

  const server500 = returnData?.error?.status === 500;

  return (
    <div className="return">
      <UserInformation
        error={ userError }
        userInformation={ userInformation }
        setUserInformation={ setUserInformation }
        setUserError={ setUserError }
        errorRef={ errorRef }/>
      <OrderInformation
        orderNumber={ userInformation.number }
        itemsToReturn={ itemsToReturn }
        setItemsToReturn={ setItemsToReturn }
        rawItems={ authentication.user.order.lines }
        error={ itemsError }
        errorRef={ errorRef }/>
      { returnData.error && <Alert type="error" className="alert">
        <Title>{ _("serverErrorNotificationTitle") }</Title>
        { _("serverErrorNotificationContent") }
        <a href={ COOP_HELPDESK_GENERAL_FORM }>{ _("here") }</a>
      </Alert> }
      <div className="buttons">
        { loading ? <Loader type="inline" size={ 20 }/> :
          <React.Fragment>
            <Button text={ _("cancelButtonText") } onClick={ handleCancel } size={ "medium" } isStretched isPrimary/>
            <Button
              text={ _("submitButtonText") }
              onClick={ handleSubmit }
              size={ "medium" }
              isStretched
              isPrimary
              disabled={ cleanItems.length < 1 || server500 || loading }/>
          </React.Fragment>
        }
      </div>
    </div>
  );
};

Return.propTypes = {
  submitReturn: PropTypes.func,
  logout: PropTypes.func,
  authentication: PropTypes.shape({
    user: userType,
    loggedIn: PropTypes.bool,
  }),
  returnData: PropTypes.object,
};

export default connect(
  state => ({
    authentication: state.authentication,
    returnData: state.returnData,
  }),
  {
    submitReturn: returnActions.submitReturn,
    logout: userActions.logout,
  },
)(Return);
