import PropTypes from "prop-types";
import React from "react";
import "./weekday.scss";

const Weekday = ({ weekday, className, localeUtils, locale }) => {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
  return (
    <div className={ className } title={ weekdayName }>
      { weekdayName.slice(0, 3) }
    </div>
  );
};

Weekday.propTypes = {
  className: PropTypes.string,
  weekday: PropTypes.number,
  localeUtils: PropTypes.object,
  locale: PropTypes.string,
};

export default Weekday;