import Title from "components/title";
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Body, Head, HeaderCell, HeaderRow, Table as GardenTable } from "@zendeskgarden/react-tables";
import "./table.scss";

/**
 * Table component.
 *
 * @param props
 * @param {string []} props.headerValues - Array of column names
 * @param {element|element[]} props.children - The content of the table, e.g. data table item component
 * @param {string} props.title - Title of the table
 * @param {boolean} props.isVertical - Determine if the table should display headers vertical
 */
const Table = ({ headerValues, children, title, isVertical }) => {
  return (
    <div className={ cx("table", { "table--vertical": isVertical }) }>
      <Title size={ "medium" } color={ "dark" }>{ title }</Title>
      <GardenTable size={ "large" }>
        <Head>
          <HeaderRow>
            { headerValues && headerValues.map((columnName) => (
              <HeaderCell key={ columnName } isTruncated>{ columnName }</HeaderCell>
            )) }
          </HeaderRow>
        </Head>
        <Body>
          { children }
        </Body>
      </GardenTable>
    </div>
  );
};

Table.propTypes = {
  headerValues: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  title: PropTypes.string,
  isVertical: PropTypes.bool,
};

export default Table;