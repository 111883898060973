import PropTypes from "prop-types";
import React from "react";
import { gettext as _ } from "src/utils/translation";

/**
 * File input
 *
 * @param {function} onChange -  An event handler for onChange events. Is activated when the input changes.
 * @param id - string to differentiate file inputs
 * @returns {JSX.Element}
 */
const FileInput = ({ onChange, id }) => {
  return (
    <div className="input-button">
      <label htmlFor={ id }> { _("uploadImageButton") } </label>
      <input id={ id } type="file" name={ id } onChange={ onChange } accept=".jpg, .jpeg, .png"/>
    </div>
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string,
};

export default FileInput;