import React from "react";
import PropTypes from "prop-types";
import { Field, Radio, Label } from "@zendeskgarden/react-forms";

/**
 * Renders a single radio button. Has to be created within a RadioField component.
 *
 * @see {@link RadioField}
 *
 * @param current
 * @param onChange
 * @param value
 * @param label
 * @return {*}
 * @constructor
 */
const RadioButton = ({ current, onChange, value, label }) => {
  const isChecked = current === value;

  return (
    <Field>
      <Radio value={ value } checked={ isChecked } onChange={ onChange }>
        <Label isRegular>{ label }</Label>
      </Radio>
    </Field>
  );
};

RadioButton.propTypes = {
  current: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default RadioButton;