import { Field, Input, Label, Message } from "@zendeskgarden/react-forms";
import PropTypes from "prop-types";
import React, { useState } from "react";
import "./currency.scss";

/**
 * Currency field component.
 *
 * @param {string} label - A string that will be displayed in a label.
 * @param {string} placeholder - A string that will be shown as a default input format in input field.
 * @param {boolean} disabled - A boolean that toggles disabled status.
 * @param {function} onChange - A function that is called for each change in the field
 * @param {string} warning - A string that indicates that there is a mistake in the input.
 * @param {boolean} required - Used to define if the input field is required, renders an asterisk
 * @param {string} errorMessage Message shown if there is an error
 * @param {string} currency - String that represents currency symbol (DKK, Kr., $)
 * @param {function|Object} errorRef - React reference that selects this element as a focus element to scroll to when error occurs
 */
const Currency = ({ label, onChange, warning, placeholder, disabled, required, errorMessage, currency, errorRef }) => {
  const decimalValue = new RegExp("^\\d+(?:\\.\\d{0,2})?$");
  const [inputValue, setInputValue] = useState("");

  const _onChange = (e) => {
    let newValue = e.target.value.replace(`${ currency } `, "");
    const testDecimalValue = decimalValue.test(newValue);

    if(testDecimalValue && newValue.length > 0) {
      setInputValue(`${ currency } ` + newValue);
      return onChange(newValue);
    } else if(newValue.length > 0) {
      setInputValue(`${ currency } ` + newValue.slice(0, -2));
    } else {
      setInputValue(newValue);
      return onChange(null);
    }
  };

  const showErrorMessage = !inputValue && errorMessage;

  return (
    <Field className="currency">
      <div className="label" ref={ errorRef }>
        { label && (<Label>{ label }</Label>) }
        { required && <span className="input-field--required">*</span> }
      </div>
      { warning && (<Message validation="warning">{ warning }</Message>) }
      <Input
        placeholder={ placeholder }
        value={ inputValue }
        onChange={ _onChange }
        onKeyDown={ e => (e.charCode >= 48 && e.charCode <= 57) }
        disabled={ disabled }/>
      { showErrorMessage && errorMessage && (
        <Message validation="error" style={ { margin: "1em" } }>
          { errorMessage }
        </Message>
      ) }
    </Field>
  );
};

Currency.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  warning: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  currency: PropTypes.string,
  errorRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default Currency;