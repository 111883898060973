import PropTypes from "prop-types";
import React, { useState } from "react";

import "src/styles/amount-input.scss";
import { itemType } from "types";

const AmountInput = ({ startingValue, updateItem, min, max, disabled, item }) => {
  const [value, setValue] = useState(startingValue);

  const decreaseValue = () => {
    const newValue = value - 1;
    setValue(newValue);
    updateItem(item, "returnAmount", Number(newValue));
  };

  const increaseValue = () => {
    const newValue = value + 1;
    setValue(newValue);
    updateItem(item, "returnAmount", Number(newValue));
  };

  return (
    <div className="amount-input">
      <button className="minus" onClick={ decreaseValue } disabled={ disabled || (value <= min) }>-</button>
      <input type="number" value={ value } max={ max } min={ min } disabled={ true }/>
      <button className="plus" onClick={ increaseValue } disabled={ disabled || (value >= max) }>+</button>
    </div>
  );
};

AmountInput.propTypes = {
  startingValue: PropTypes.number,
  updateItem: PropTypes.func,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  item: itemType,
};

export default AmountInput;