import Button from "components/button";
import InputField from "components/input-field";
import PropTypes from "prop-types";
import React from "react";
import { validateEmail, validatePhoneNumber, validatePostCode } from "src/helpers";
import { gettext as _ } from "src/utils/translation";

import "styles/user-information.scss";
import { userType } from "types";

const UserInformationEdit = ({ error, userInformation, setUserInformation, toggleEdit }) => {
  const { email, membershipnumber, taddr_city, taddr_line1, taddr_name, taddr_postal_code, number, phone_number } = userInformation;

  const handleInputChange = (e) => {
    let value = e.target.value;
    const name = e.target.name;
    if(name === "email") {
      value = value.replace(/\s+/g, "");
      value = value.toLowerCase();
    }
    setUserInformation({
      ...userInformation,
      [name]: value,

    });
  };

  const fullNameError = error && (!taddr_name || taddr_name === "");
  const addressError = error && (!taddr_line1 || taddr_line1 === "");
  const cityError = error && (!taddr_city || taddr_city === "");
  const phoneNumberError = !validatePhoneNumber(phone_number);
  const zipcodeError = !validatePostCode(taddr_postal_code) || error && (!taddr_postal_code || taddr_postal_code === "");
  const emailError = !validateEmail(email);

  return (
    <div className="user-information-edit">
      <InputField label={ _("orderNumberInputLabel") } placeholder={ _("orderNumberInputPlaceholder") } disabled={ true } value={ number }/>
      <InputField
        label={ _("membershipNumberInputLabel") }
        onChange={ handleInputChange }
        value={ membershipnumber }
        name="membershipnumber"/>
      <InputField
        label={ _("fullNameInputLabel") }
        onChange={ handleInputChange }
        value={ taddr_name }
        isError={ fullNameError }
        errorMessage={ _("fullNameInputErrorMessage") }
        name="taddr_name"/>
      <InputField
        label={ _("addressInputLabel") }
        onChange={ handleInputChange }
        value={ taddr_line1 }
        isError={ addressError }
        errorMessage={ _("addressInputErrorMessage") }
        name="taddr_line1"/>
      <InputField
        label={ _("postalCodeInputLabel") }
        onChange={ handleInputChange }
        value={ taddr_postal_code }
        name="taddr_postal_code"
        isError={ zipcodeError }
        errorMessage={ _("postalCodeInputErrorMessage") }/>
      <InputField
        label={ _("cityInputLabel") }
        onChange={ handleInputChange }
        value={ taddr_city }
        name="taddr_city"
        isError={ cityError }
        errorMessage={ _("cityInputErrorMessage") }/>
      <InputField
        label={ _("phoneNumberInputLabel") }
        onChange={ handleInputChange }
        value={ phone_number }
        isError={ phoneNumberError }
        errorMessage={ _("phoneNumberInputErrorMessage") }
        name="phone_number"/>
      <InputField
        label={ _("emailInputLabel") }
        value={ email }
        onChange={ handleInputChange }
        isError={ emailError }
        errorMessage={ _("emailInputErrorMessage") }
        name="email"/>
      <br/>
      <Button onClick={ toggleEdit } size={ "medium" } text={ _("userInformationModalButtonSave") } isStretched={ false } isPrimary/>
    </div>
  );
};

UserInformationEdit.propTypes = {
  error: PropTypes.bool,
  userInformation: userType,
  setUserInformation: PropTypes.func,
  toggleEdit: PropTypes.func,
};

export default UserInformationEdit;
