import { Field, Label, Message, Textarea } from "@zendeskgarden/react-forms";
import PropTypes from "prop-types";
import React, { memo } from "react";
import "./multiline-text.scss";

/**
 * MultilineText field component.
 *
 * @param {string} label - The title shown above the input field
 * @param {function} onChange -  An event handler for onChange events. Is activated when the input changes.
 * @param {number} maxRows - Defines maximum number of rows in the component
 * @param {number} minRows - Defines minimum number of rows in the component
 * @param {string} value - The text typed into the input field.
 * @param {boolean} required - Used to define if the input field is required, renders an asterisk
 * @param {string} errorMessage Message shown if there is an error
 * @param {function|Object} errorRef - React reference that selects this element as a focus element to scroll to when error occurs
 */
const MultilineText = ({ label, minRows, maxRows, onChange, value, required, errorMessage, errorRef }) => {
  return (
    <div className={ "multiline-text" } ref={ errorRef }>
      <Field>
        <div className="label">
          { label && <Label>{ label }</Label> }
          { required && <span className="input-field--required">*</span> }
        </div>
        <Textarea minRows={ minRows } maxRows={ maxRows } onChange={ onChange } value={ value }/>
        { errorMessage && (
          <Message validation="error" style={ { margin: "1em" } }>
            { errorMessage }
          </Message>
        ) }
      </Field>
    </div>
  );
};

MultilineText.propTypes = {
  label: PropTypes.string,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default memo(MultilineText);