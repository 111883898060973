const ORDER_CONSTANTS = {
  ORDER_REQUEST: "GET_ORDER_REQUEST",
  ORDER_SUCCESS: "GET_ORDER_SUCCESS",
  ORDER_FAILURE: "GET_ORDER_FAILURE",
};

const initialState = {};

export default function orderItems(state = initialState, action) {
  switch(action.type) {
    case ORDER_CONSTANTS.ORDER_REQUEST:
      return {
        items: action.orderItemsData,
      };
    case ORDER_CONSTANTS.ORDER_SUCCESS:
      return {
        items: action.orderItemsData,
      };
    case ORDER_CONSTANTS.ORDER_FAILURE:
      return {};
    default:
      return state;
  }
}