import Button from "components/button";
import Table from "components/table";
import Item from "components/table/item";
import Title from "components/title";
import UserInformation from "containers/user-information";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { userActions } from "src/actions/userActions";
import coopLogo from "src/images/Coop_logo.png";
import printImg from "src/images/print.svg";
import { gettext as _ } from "src/utils/translation";

import "styles/summary.scss";

/**
 * Summary page - shows data about the items user submitted for return
 *
 * @param returnData - Data returned from submitting the return form
 * @param logout - Logout action
 */
const Summary = ({ returnData, logout }) => {
  const items = returnData && returnData.items;
  if(!items) {
    logout();
  }

  window.onunload = () => {
    logout();
  };

  const history = useHistory();

  useEffect(() => {
    return () => {
      if(history.action === "POP") {
        logout();
        history.push("/");
      }
    };
  }, []);

  const user = items && returnData.items[0];

  let headerValues = [
    _("tableHeaderValueItem"),
    _("tableHeaderValueAmount"),
    _("tableHeaderValueReturnReason"),
    _("tableHeaderValueShipping"),
    _("tableHeaderValueReturnAction"),

  ];

  if(items?.some(item => item.unifaun_tracking_id && item.unifaun_tracking_id !== "")) {
    headerValues.push([_("tableHeaderValueTrackingNumber")]);
  }

  if(items?.some(item => item.shipping_label && item.shipping_label !== "")) {
    headerValues.push([_("tableHeaderValueLabel")]);
  }

  const handleLogout = () => {
    logout();
    window.location.href = "https://shopping.coop.dk/";
  };

  const handleNewReturn = () => {
    logout();
    history.push("/");
  };

  return (
    <div className="summary">
      <Title size="large">{ _("summaryTitle") }</Title>
      <p className="summary-subtitle">
        { _("summarySubtitle") }
      </p>
      { user && <UserInformation userInformation={ user }/> }
      <Table headerValues={ headerValues } title={ _("summaryTableTitle") } isVertical>
        { items && items.map((item, index) => {
          const label = "data:application/pdf;base64," + item.shipping_label;
          let values = [item.name, parseInt(item.return_amount), _(item.reason_category), _(item.shipping_type), _(item.return_action)];
          if(item.unifaun_tracking_id && item.unifaun_tracking_id !== "") {
            values.push([<div key={ `tracking-number ${ index }` } className="tracking-number">{ item.unifaun_tracking_id }</div>]);
          }
          if(item.shipping_type === "return_label") {
            values.push([<a key={ `return item link ${ index }` } href={ label } className="print-label-link" download="coop_label">
              <img alt="print-label" src={ printImg }/>
              { _("printLabelText") }
            </a>]);
          }
          return (
            <Item key={ `return item ${ index }` } values={ values }/>
          );
        }) }
      </Table>
      <br/>
      <div className="summary-message">
        <p className="bold">
          { _("summaryMessage") }
          <br/>
          { _("summaryMessage2") }
        </p>
        <p>
          { _("summaryMessage3") }
          <br/>
          <span className="bold">Coop.dk</span>
        </p>
        <p>
          <span className="bold">Coop Danmark A/S</span>
          <br/>
          Roskildevej 65
          <br/>
          DK-2620 Albertslund
          <br/>
          CVR-nr. 26259495
          <br/>
          <span className="bold">www.coop.dk</span>
        </p>
        <img alt="coop-logo" src={ coopLogo } className="logo"/>
        <p className="summary-disclaimer">
          { _("summaryDisclaimer") }
        </p>
      </div>
      <div className="buttons">
        <Button text={ _("newReturnButtonText") } onClick={ handleNewReturn } size={ "medium" } isPrimary/>
        <Button text={ _("logoutButtonText") } onClick={ handleLogout } size={ "medium" } isPrimary/>
      </div>
    </div>
  );
};

Summary.propTypes = {
  returnData: PropTypes.object,
  logout: PropTypes.func,
};

export default connect(
  state => ({
    returnData: state.returnData,
    authentication: state.authentication,
  }),
  {
    logout: userActions.logout,
  },
)(Summary);
