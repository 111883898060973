import { Label, Message } from "@zendeskgarden/react-forms";
import PropTypes from "prop-types";
import React from "react";
import RadioButton from "./radio-button";

import "./single-option.scss";

/**
 * The child of SingleOption should be a function.
 * The functions' responsibility is to delegate props to subcomponents.
 *
 * @param label {string} props.label - The title shown above the input field
 * @param {array} options - Array of the values displayed in the radio fields.
 * @param {string} current - The value of the selected radio button within a group.
 * @param {function} onChange - An event handler for onChange events. Is activated when the radio button is clicked.
 * @param {boolean} required - Used to define if the input field is required, renders an asterisk
 * @param {string} errorMessage Message shown if there is an error
 * @param {function|Object} errorRef - React reference that selects this element as a focus element to scroll to when error occurs
 */
const SingleOption = ({ label, options, onChange, current, required, errorMessage, errorRef }) => {
  return (
    <div className="single-option" ref={ errorRef }>
      <div className="label">
        { label && <Label>{ label }</Label> }
        { required && <span className="input-field--required">*</span> }
      </div>
      <div className="options">
        { options.map((option, index) => {
          return (
            <RadioButton key={ index } value={ option.value } label={ option.label } onChange={ onChange } current={ current }/>
          );
        }) }
        { errorMessage && (
          <Message validation="error">
            { errorMessage }
          </Message>
        ) }
      </div>
    </div>
  );
};

const optionShape = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

SingleOption.propTypes = {
  options: PropTypes.arrayOf(optionShape).isRequired,
  current: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default SingleOption;