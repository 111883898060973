import { Field, Label, Message } from "@zendeskgarden/react-forms";
import Holidays from "date-holidays";
import PropTypes from "prop-types";
import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, { formatDate, parseDate } from "react-day-picker/moment";
import { getLang, gettext as _ } from "src/utils/translation";
import { Navbar, Weekday } from "./";
import "./date-picker.scss";

/**
 * Datepicker component.
 *
 * @param {Date} date - Currently selected date value
 * @param {function} onSelect -  An event handler for onSelect events. Is activated when the input changes.
 * @param {string} label - The title shown above the input field
 * @param {string} errorMessage Message shown if the user selects the wrong date
 * @param {Date} minValue Last date that user can choose, before this everything is disabled
 * @param {Date} maxValue Last date that user can choose, after this everything is disabled
 * @param {boolean} required - Used to define if the input field is required, renders an asterisk
 */
const Datepicker = ({ date, onSelect, label, errorMessage, minValue, maxValue, required }) => {
  const locale = getLang();
  let hd = new Holidays("DK"); //locale for Denmark is DA so this is hardcoded
  const thisYear = new Date().getFullYear();
  const nextYear = thisYear + 1;
  const allDanishHolidays = hd.getHolidays(thisYear).concat(hd.getHolidays(nextYear));

  //filter only public holidays, but leave 12-24, 06-05 and 05-01 according to postnord policy https://www.postnord.dk/siteassets/pdf/forretningsbetingelser/eng/2021/parcels_v2.pdf
  const excludeRules = ["12-24", "06-05", "05-01"];
  const publicHolidays = allDanishHolidays.filter(holiday => {
    return holiday.type === "public" || excludeRules.includes(holiday.rule);
  });
  const holidaysForTwoYears = publicHolidays.map(holiday => new Date(holiday.date));
  //coop asked to exclude new years eve too
  const newYearsEves = [new Date(thisYear, 11, 31), new Date(nextYear, 11, 31)];

  return (
    <Field className="datepicker">
      <div className="label">
        { label && (<Label>{ label }</Label>) }
        { required && <span className="input-field--required">*</span> }
      </div>
      <DayPickerInput
        placeholder={ _("datepickerPlaceHolder") }
        selectedDays={ date }
        onDayChange={ onSelect }
        formatDate={ formatDate }
        parseDate={ parseDate }
        format="LL"
        dayPickerProps={ {
          showOutsideDays: true,
          locale: locale,
          localeUtils: MomentLocaleUtils,
          disabledDays: [
            ...holidaysForTwoYears,
            ...newYearsEves,
            {
              before: minValue,
              after: maxValue || newYearsEves[1],
            },
            {
              daysOfWeek: [0, 6],
            }],
          navbarElement: <Navbar/>,
          captionElement: <span style={ { display: "none" } }/>,
          weekdayElement: <Weekday/>,
        } }/>
      { errorMessage && (
        <Message validation="error" style={ { margin: "1em" } }>
          { errorMessage }
        </Message>
      ) }
    </Field>);
};

Datepicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  minValue: PropTypes.instanceOf(Date),
  maxValue: PropTypes.instanceOf(Date),
  required: PropTypes.bool,
};

export default Datepicker;