import { Cell, Row } from "@zendeskgarden/react-tables";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import "./item.scss";

/**
 * Table item component.
 *
 * @param {boolean} isDisabled - Used to define if the item should be disabled.
 * @param {$ElementType []} values - Array of react components that represent table cells (values) in one row
 * @param {boolean} isTruncated - Truncates long text with an ellipsis
 */
const Item = ({ isDisabled, values, isTruncated }) => {
  return (
    <Row key={ `item row ${ values }` } className={ cx("row", { "row--disabled": isDisabled }) }>
      { values && values.map((value, index) => (
        <Cell className={ `td-${ index }` } key={ `item row cell ${ index }` } isTruncated={ isTruncated } hasOverflow>
          { value }
        </Cell>
      )) }
    </Row>
  )
  ;
};

Item.propTypes = {
  isDisabled: PropTypes.bool,
  isTruncated: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
  ])).isRequired,
};

export default Item;