import React from "react";
import deleteIcon from "src/images/delete.svg";

const Images = ({ images, removeImage }) =>
  images.map((image, i) =>
    <div key={ i } className="image">
      <div onClick={ () => removeImage(image.file_token) } className="delete">
        <img alt="delete" src={ deleteIcon } width={ 16 } height={ 16 }/>
      </div>
      <span>{ image.name }</span>
    </div>,
  );

export default Images;