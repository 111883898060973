import SingleOption from "components/single-option";
import PropTypes from "prop-types";
import React from "react";
import { gettext as _ } from "src/utils/translation";

/**
 * Radio button group component that displays different shipping options
 *
 * @param {function} onChange  - Function that triggers when user clicks on a different option
 * @param {string} current - Currently selected option
 * @param {boolean} error - Is there an error in submitted return
 * @param {array} shippingOptions - Shipping options
 * @returns {JSX.Element}
 */
const ShippingOption = ({ onChange, current, error, shippingOptions, errorRef }) => {
  const errorMessage = error ? _("shippingOptionErrorMessage") : null;

  return <SingleOption
    onChange={ onChange }
    options={ shippingOptions }
    current={ current }
    label={ _("shippingOptionSingleOptionLabel") }
    errorMessage={ errorMessage }
    errorRef={ errorRef }/>;
};

ShippingOption.propTypes = {
  current: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  shippingOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  errorRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default ShippingOption;