import React from "react";
import PropTypes from "prop-types";
import { Tooltip as GardenTooltip } from "@zendeskgarden/react-tooltips";

/**
 * Tooltip component.
 *
 * @param props
 * @property {element|element[]} props.children - The text displayed in the tooltip.
 * @property {element} props.content - The content displayed in the tooltip.
 * @property {string} props.size - The size of the tooltip, can be small, medium, large or extra-large.
 * @property {string} props.type - The type of the tooltip, can be light or dark.
 * @property {string} props.placement - The placement of the tooltip, can be top, bottom, start or end.
 */
const Tooltip = ({ children, content, size, type, placement  }) => {
  return(
    <GardenTooltip content={ content } size={ size } type={ type ? type : "light" } placement={ placement ? placement : "bottom-start" } delayMS={ 100 }>
      { children }
    </GardenTooltip>
  );
};

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  content: PropTypes.element.isRequired,
  size: PropTypes.oneOf([
    "small",
    "medium",
    "large",
    "extra-large",
  ]).isRequired,
  type:  PropTypes.oneOf(["light", "dark"]),
  placement: PropTypes.oneOf([
    "auto",
    "top",
    "top-start",
    "top-end",
    "end",
    "end-top",
    "end-bottom",
    "bottom",
    "bottom-start",
    "bottom-end",
    "start",
    "start-top",
    "start-bottom",
  ]),
};

export default Tooltip;