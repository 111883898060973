let translation;

function tryRequire(locale) {
  try {
    return require(`../translations/${ locale }.json`);
  } catch (e) {
    return null;
  }
}

const Translation = Object.freeze({
  t: function (key) {
    if(!translation) {
      throw new Error("Translations must be initialized with I18n.loadTranslations before calling `t`.");
    }
    let keyType = typeof key;
    if(keyType !== "string") {
      throw new Error(`Translation key must be a string, got: ${ keyType }`);
    }
    let template = translation[key];
    if(!template) {
      throw new Error(`Missing translation: ${ key }`);
    }
    return template;
  },
  loadTranslations: function (locale) {
    translation = tryRequire(locale) ||
      tryRequire(locale.replace(/-.+$/, "")) || // e.g. fallback `en-US` to `en`
      tryRequire("da") ||
      {};
  },
});

export function gettext(key) {
  try {
    return Translation.t(key);
  } catch (e) {
    return key;
  }
}

export function getLang() {
  if(navigator.languages !== undefined) {
    return navigator.languages[0];
  }
  return navigator.language;
}


export default Translation;