import { Row, Cell } from "@zendeskgarden/react-tables";
import cx from "classnames";
import SingleOption from "components/single-option";
import Item from "components/table/item";
import PickupInstructions from "containers/pickup-instructions";
import { itemType } from "types";
import ShippingOption from "containers/shipping-option";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { gettext as _ } from "src/utils/translation";

import "src/styles/wrong-item.scss";

/**
 * WrongItem component
 *
 * @param rawItem {itemType}
 * @param values {(string | number | $ElementType) []}
 * @param updateItem {function}
 * @param isSelected {boolean}
 * @param returnItem
 * @param error {boolean}
 * @returns {JSX.Element}
 */

const WrongItem = ({ rawItem, values, updateItem, isSelected, returnItem, error }) => {
  const [selectedActionOption, setSelectedActionOption] = useState();
  const [selectedReturnOption, setSelectedReturnOption] = useState();
  const { warehouse } = rawItem;

  const actionOptions = [
    { label: _("reimbursementRadioButton"), value: "reimbursement" },
    { label: _("exchangeRadioButton"), value: "exchange" },
  ];

  const handleActionChange = (e) => {
    setSelectedActionOption(e.target.value);
    updateItem(rawItem, "return_action", e.target.value);
    if(warehouse.toString() !== "86"){
      updateItem(rawItem, "shipping_type", "pickup_home");
    }
  };

  useEffect(() => {
    let cells = document.querySelectorAll(`td[width="${ 2 }"]`);
    cells.forEach((cell) => {
      cell.setAttribute("colspan", "2");
    });
  });

  const handleReturnOptionChange = (e) => {
    setSelectedReturnOption(e.target.value);
    updateItem(rawItem, "shipping_type", e.target.value);
  };

  useEffect(() => {
    const noPickupDate = returnItem.shipping_type === "pickup_home" && !returnItem.pickup_datetime;
    if(returnItem && (!selectedActionOption || !selectedReturnOption) || noPickupDate) {
      updateItem(rawItem, "error", true);
    } else {
      updateItem(rawItem, "error", false);
    }
  }, [returnItem, selectedActionOption, selectedReturnOption]);

  const returnActionErrorMessage = error && returnItem && !selectedActionOption ? _("returnActionSingleOptionErrorMessage") : null;
  const returnActionErrorRef = returnActionErrorMessage ? returnItem.errorRef : undefined;
  const isShippingOptionError = error && returnItem && !selectedReturnOption;
  const shippingOptionErrorRef = isShippingOptionError ? returnItem.errorRef : undefined;
  const pickupInstructionsClassName = selectedReturnOption === "pickup_home" ? "pickup-instructions-long" : "pickup-instructions-short";
  const shippingOptions = [
    { label: _("printLabelRadioButton"), value: "return_label" },
    { label: _("packageBoxRadioButton"), value: "package_box" },
    { label: _("pickupRadioButton"), value: "pickup_home" }];

  return <React.Fragment>
    <Item values={ values } isSelected={ isSelected }/>
    <Row key={ `item row ${ values } selected` } className={ cx("row", "row--selected", "wrong-item") }>
      <Cell key={ `item row cell ${ values } radio` } hasOverflow width={ 2 }>
        <SingleOption
          onChange={ handleActionChange }
          options={ actionOptions }
          current={ selectedActionOption }
          label={ _("returnActionSingleOptionLabel") }
          errorMessage={ returnActionErrorMessage }
          errorRef={ returnActionErrorRef }/>
      </Cell>
      { Boolean(selectedActionOption) && (
        <React.Fragment>
          { warehouse.toString() === "86" && (
            <Cell key={ `item row cell ${ values } radio` } hasOverflow width={ 2 }>
              <ShippingOption
                onChange={ handleReturnOptionChange }
                current={ selectedReturnOption }
                error={ isShippingOptionError }
                errorRef={ shippingOptionErrorRef }
                shippingOptions={ shippingOptions }/>
            </Cell>
          ) }
          { (warehouse.toString() !== "86" || selectedReturnOption === "pickup_home") && (
            <Cell key={ `item row cell ${ values } date` } hasOverflow width={ 2 }>
              <PickupInstructions
                item={ rawItem }
                updateItem={ updateItem }
                returnItem={ returnItem }
                error={ error }
                className={ pickupInstructionsClassName }/>
            </Cell>
          ) }
        </React.Fragment>
      ) }
    </Row>
  </React.Fragment>;
};

WrongItem.propTypes = {
  values: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
  ])).isRequired,
  rawItem: itemType,
  isSelected: PropTypes.bool,
  updateItem: PropTypes.func,
  returnItem: itemType,
  error: PropTypes.bool,
};

export default WrongItem;