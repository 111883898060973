import { Cell, Row } from "@zendeskgarden/react-tables";
import cx from "classnames";
import Currency from "components/currency";
import MultilineText from "components/multiline-text";
import SingleOption from "components/single-option";
import Item from "components/table/item";
import PickupInstructions from "containers/pickup-instructions";
import ShippingOption from "containers/shipping-option";
import ImageUpload from "containers/upload-images/image-upload";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { gettext as _ } from "src/utils/translation";

import "styles/claim-defect.scss";
import { itemType } from "types";

/**
 * ClaimDefect component
 *
 * @param rawItem {itemType}
 * @param values {(string | number | $ElementType) []}
 * @param updateItem {function}
 * @param isSelected {boolean}
 * @param returnItem {itemType}
 * @param error {boolean}
 * @returns {JSX.Element}
 */

const ClaimDefect = ({ rawItem, values, updateItem, isSelected, returnItem, error }) => {
  const [selectedActionOption, setSelectedActionOption] = useState();
  const [selectedShippingOption, setSelectedShippingOption] = useState();
  const [images, setImages] = useState([]);
  const [deduction, setDeduction] = useState("");
  const [defectDescription, setDefectDescription] = useState("");
  const { warehouse } = rawItem;

  const actionOptions = [
    { label: _("reimbursementRadioButton"), value: "reimbursement" },
    { label: _("exchangeRadioButton"), value: "exchange" },
    { label: _("deductionRadioButton"), value: "deduction" },
  ];

  const handleActionChange = (e) => {
    setSelectedActionOption(e.target.value);
    setSelectedShippingOption(null);
    updateItem(rawItem, "return_action", e.target.value);
    if(warehouse.toString() !== "86") {
      updateItem(rawItem, "shipping_type", "pickup_home");
    }
  };

  const handleMultilineTextChange = (e) => {
    setDefectDescription(e.target.value);
    updateItem(rawItem, "defect", e.target.value);
  };

  useEffect(() => {
    let cells2 = document.querySelectorAll(`td[width="${ 2 }"]`);
    cells2.forEach((cell) => {
      cell.setAttribute("colspan", "2");
    });

    let cells1 = document.querySelectorAll(`td[width="${ 1 }"]`);
    cells1.forEach((cell) => {
      cell.setAttribute("colspan", "1");
    });
  });

  useEffect(() => {
    // for email templates - we want to set shipping type to deduction so that we can select a correct email template
    if(selectedActionOption === "deduction") {
      updateItem(rawItem, "shipping_type", "deduction");
    }
  }, [selectedActionOption]);

  const handleShippingOptionChange = (e) => {
    setSelectedShippingOption(e.target.value);
    updateItem(rawItem, "shipping_type", e.target.value);
  };

  const handleDeductionChange = (value) => {
    setDeduction(value);
    updateItem(rawItem, "deduction_amount", value);
  };

  const handleSetImages = (images) => {
    setImages(images);
    const imageTokens = images.map(image => image.file_token);
    updateItem(rawItem, "imgs", imageTokens.join(","));
  };

  useEffect(() => {
    const noPickupDate = returnItem.shipping_type === "pickup_home" && !returnItem.pickup_datetime;
    if(returnItem && (!selectedActionOption || (selectedActionOption === "deduction" && !deduction) || ((warehouse.toString() === "86" && selectedActionOption !== "deduction") && !selectedShippingOption) || !defectDescription) || noPickupDate) {
      updateItem(rawItem, "error", true);
    } else {
      updateItem(rawItem, "error", false);
    }
  }, [returnItem, selectedActionOption, selectedShippingOption, defectDescription, deduction]);

  const defectErrorMessage = error && returnItem && !defectDescription ? _("defectMultilineTextErrorMessage") : null;
  const defectErrorRef = defectErrorMessage ? returnItem.errorRef : undefined;
  const returnActionErrorMessage = error && returnItem && !selectedActionOption ? _("returnActionSingleOptionErrorMessage") : null;
  const returnActionErrorRef = returnActionErrorMessage ? returnItem.errorRef : undefined;
  const isShippingOptionError = error && returnItem && !selectedShippingOption;
  const shippingOptionErrorRef = isShippingOptionError ? returnItem.errorRef : undefined;
  const deductionErrorMessage = error && returnItem && selectedActionOption === "deduction" && !deduction ? _("deductionInputErrorMessage") : null;
  const deductionErrorRef = deductionErrorMessage ? returnItem.errorRef : undefined;
  const cellWidth = selectedShippingOption === "pickup_home" ? 1 : 2;

  const shippingOptions = [
    { label: _("printLabelRadioButton"), value: "return_label" },
    { label: _("packageBoxRadioButton"), value: "package_box" },
    { label: _("pickupRadioButton"), value: "pickup_home" }];

  return <React.Fragment>
    <Item values={ values } isSelected={ isSelected }/>
    <Row key={ `item row ${ values } selected` } className={ cx("row", "row--selected", "claim") }>
      <Cell key={ `item row cell ${ values } multitext` } hasOverflow width={ 2 } className="describe-defect-cell">
        <MultilineText
          label={ _("defectMultilineTextLabel") }
          onChange={ handleMultilineTextChange }
          errorMessage={ defectErrorMessage }
          errorRef={ defectErrorRef }/>
        <ImageUpload id={ rawItem.line_id } images={ images } setImages={ handleSetImages }/>
      </Cell>
      <Cell key={ `item row cell ${ values } radio action` } hasOverflow width={ cellWidth }>
        <SingleOption
          onChange={ handleActionChange }
          options={ actionOptions }
          current={ selectedActionOption }
          label={ _("returnActionSingleOptionLabel") }
          errorMessage={ returnActionErrorMessage }
          errorRef={ returnActionErrorRef }/>
      </Cell>
      { selectedActionOption && (selectedActionOption === "deduction" ? (
        <Cell key={ `item row cell ${ values } currency` } hasOverflow width={ 2 }>
          <Currency
            currency={ _("currency") }
            onChange={ handleDeductionChange }
            value={ deduction }
            label={ _("deductionInputLabel") }
            placeholder={ _("deductionInputPlaceholder") }
            errorMessage={ deductionErrorMessage }
            errorRef={ deductionErrorRef }/>
        </Cell>
      ) : (
        <React.Fragment>
          { warehouse.toString() === "86" && (
            <Cell key={ `item row cell ${ values } radio shipping` } hasOverflow width={ cellWidth }>
              <ShippingOption
                onChange={ handleShippingOptionChange }
                current={ selectedShippingOption }
                error={ isShippingOptionError }
                errorRef={ shippingOptionErrorRef }
                shippingOptions={ shippingOptions }/>
            </Cell>
          ) }
          { (warehouse.toString() !== "86" || selectedShippingOption === "pickup_home") && (
            <Cell key={ `item row cell ${ values } date` } hasOverflow width={ 2 }>
              <PickupInstructions item={ rawItem } updateItem={ updateItem } returnItem={ returnItem } error={ error }/>
            </Cell>
          ) }
        </React.Fragment>
      )) }
    </Row>
  </React.Fragment>;
};

ClaimDefect.propTypes = {
  values: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
  ])).isRequired,
  rawItem: itemType,
  isSelected: PropTypes.bool,
  updateItem: PropTypes.func,
  returnItem: itemType,
  error: PropTypes.bool,
};

export default ClaimDefect;