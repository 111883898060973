import { get_cookie } from "../utils";

const getHeaders = () => {
  let default_headers = {
    "Content-Type": "application/json",
  };

  let csrftoken = get_cookie("csrftoken");

  if(csrftoken) {
    default_headers["X-CSRFToken"] = csrftoken;
  }

  return default_headers;
};

const createErrorObject = async (response) => {
  let error = {
    status: response.status,
    statusText: response.statusText,
  };

  if(response.json) {
    error.response = await response.json();
  }

  return error;
};

const handleErrors = async (response) => {
  if(response.ok) {
    return response.json && response.status !== 204
      ? await response.json()
      : {};
  }

  throw await createErrorObject(response);
};

const request = async (path, body, method, headers) => {
  if(typeof body !== "undefined" && typeof method === "undefined") {
    method = "POST";
  }

  if(typeof method === "undefined") {
    method = "GET";
  }

  if(typeof headers === "undefined") {
    headers = {};
  }

  headers = Object.assign({}, getHeaders(), headers);

  let options = {
    method: method,
    headers: headers,
    credentials: "include",
    body: body && JSON.stringify(body),
  };

  return await handleErrors(
    await fetch(`/api${ path }`, options),
  );
};

const get = (path, body = null, headers = undefined) => {
  return request(path, body, "GET", headers);
};

const post = (path, body = null, headers = undefined) => {
  return request(path, body, "POST", headers);
};

const patch = (path, body = null, headers = undefined) => {
  return request(path, body, "PATCH", headers);
};

const put = (path, body = null, headers = undefined) => {
  return request(path, body, "PUT", headers);
};

const deleteReq = (path, headers = undefined) => {
  return request(path, null, "DELETE", headers);
};

export { get, post, patch, put, deleteReq };
