import React from "react";
import PropTypes from "prop-types";
import { Button as GardenButton } from "@zendeskgarden/react-buttons";

import "./button.scss";

/**
 * Button component.
 *
 * @param {string|object} text - The text displayed in the button.
 * @param {function} onClick - An event handler for onClick events. Is activated when the button is clicked.
 * @param {string} size - The size of the button, can be small, medium or large.
 * @param {boolean} disabled - Used to define if the button should be disabled.
 * @param {boolean} isDanger - Used to apply danger button styling.
 * @param {boolean} isPrimary - Used to apply primary button styling.
 * @param {boolean} isStretched - Used to stretch button width to its container's width.
 * @param {boolean} isBasic - Applies basic button styling
 */
const Button = ({ text, onClick, size, disabled, isDanger, isPrimary, isStretched, isBasic }) => {
  return (
    <GardenButton onClick={ onClick } size={ size } disabled={ disabled } isDanger={ isDanger } isPrimary={ isPrimary } isStretched={ isStretched }
      isBasic={ isBasic }>
      { text }
    </GardenButton>
  );
};

Button.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf([
    "small",
    "medium",
    "large",
  ]).isRequired,
  disabled: PropTypes.bool,
  isDanger: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isStretched: PropTypes.bool,
  isBasic: PropTypes.bool,
};

export default Button;